import './App.css';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import VCard from './components/createVCard/createVCard';
import Home from './components/pages/Home';
import Datenschutz from './components/pages/Datenschutz';
import Impressum from './components/pages/Impressum';
import ScrollToTop from './components/ScrollToTop';
import QRReader from './components/readVCard/QRReader';

const App = () => (

  <Router>
    <NavBar />
    <Switch>

      <Route path="/" exact render={(props) => (
        <>
          <ScrollToTop />
          <Home {...props} />
        </>
      )}
      />
      <Route path="/home" exact render={(props) => (
        <>
          <ScrollToTop />
          <Home {...props} />
        </>
      )}
      />
      <Route path="/datenschutz" render={(props) => (
        <>
          <ScrollToTop />
          <Datenschutz {...props} />
        </>
      )}
      />
      <Route path="/readQRCode" render={(props) => (
        <>
          <ScrollToTop />
          <QRReader {...props} />
        </>
      )}
      />
      <Route path="/impressum" render={(props) => (
        <>
          <ScrollToTop />
          <Impressum {...props} />
        </>
      )}
      />
      <Route path="/vcard" 
        render={(props) => (
          <VCard {...props} />
        )}
      />

    </Switch>
  </Router>
);

export default App;
