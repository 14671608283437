import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

function Footer() {
    return (
        <div className='footer-container'>

            <div className="footer-links">
                <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>Besucher</h2>
                        <Link to='/sign-up'>Besucher Registrierung</Link>
                        <Link to='/sign-in'>Besucher Anmeldung</Link>

                    </div>
                    <div className="footer-link-items">
                        <h2>Unternehmen</h2>
                        <Link to='/sign-up'>Unternehmen Registrierung</Link>
                        <Link to='/sign-in'>Unternehmen Anmeldung</Link>
                    </div>
                    <div className="footer-link-items">
                        <h2>Rechtliches</h2>
                        <Link to='/datenschutz'>Datenschutz</Link>
                        <Link to='/impressum'>Impressum</Link>
                        <Link to='/sign-in'>Kontakt</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
