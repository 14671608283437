import React from "react";
import "./Datenschutz.css";
import Footer from "../Footer";

function Datenschutz() {
  return (
    <>
      <div className="datenschutz">
        <h1> Datenschutz </h1>
        <div>
          <h2>Datenschutzrechtliche Information zu Kunden - bzw. Gästelisten</h2>
          <p>
            Zu Ihrem Schutz und einer möglichst schnellen Nachverfolgbarkeit
            möglicher Infektionsketten mit dem neuartigen Covid - 19 -
            Virus („Corona“) sind wir verpflichtet, Ihre Anwesenheit in unserem
            Geschäft / Lokal zu dokumentieren.Nachfolgend möchten wir Sie über
            diese Datenverarbeitung informieren:
          </p>
          <h3>Wer ist Verantwortlicher für die Datenverarbeitung:</h3>
          <p>
            Zunächst sind wir(Name und Anschrift des Geschäfts / Lokal, falls
            vorhanden: Kontaktdaten des oder der Datenschutzbeauftragten) für
            die Verarbeitung der personenbezogenen Daten verantwortlich, die Sie
            uns bereitstellen, damit wir Ihren Aufenthalt in unserem Geschäft /
            Lokal dokumentieren können.Werden diese personenbezogenen Daten von
            dem zuständigen Gesundheitsamt angefordert, ist der oder die
            jeweilige Landrat / Landrätin bzw.Oberbürgermeister /
            Oberbürgermeisterin, für die Zulässigkeit der Datenübermittlung und
            die weitere Datenverarbeitung beim zuständigen Gesundheitsamt
            verantwortlich.
          </p>
          <h3> Welche personenbezogenen Daten werden benötigt ? </h3>
          <ul>
            <li> Ihr vollständiger Name </li>
            <li> Ihre vollständige Adresse </li>
            <li> Ihre Telefonnummer </li>
            <li> Datum und Uhrzeit Ihres Aufenthalts </li>
            <li> bei Restaurants : Ihre Tischnummer </li>
          </ul>
          <h3>
            Zu welchem Zweck und auf welcher Rechtsgrundlage werden diese
            personenbezogenen Daten verarbeitet ?
          </h3>
          <p>
            Nach der Verordnung der Landesregierung MV zum Übergang nach den
            Corona - SchutzMaßnahmen i.V.m.Art .6 Abs .1 lit.c DS - GVO müssen
            wir diese personenbezogenen Daten erheben und auf Verlangen dem
            zuständigen Gesundheitsamt übermitteln, falls der Verdacht besteht,
            dass es im Zusammenhang mit dem Besuch in unserem Geschäft / Lokal
            zu einer Infektion mit Covid - 19 gekommen ist. Diese
            personenbezogenen Daten dürfen wir zu keinem anderen Zweck,
            insbesondere nicht zu Werbezwecken, verwenden.An wen werden diese
            personenbezogenen Daten übermittelt ? Ihre Angaben werden von uns
            ausschließlich auf Verlangen des zuständigen Gesundheitsamtes an
            dieses übermittelt.
          </p>
          <h3> An wen werden diese personenbezogenen Daten übermittelt ? </h3>
          <p>
            Ihre Angaben werden von uns ausschließlich auf Verlangen des
            zuständigen Gesundheitsamtes an dieses übermittelt.
          </p>
          <h3> Wie lange werden die personenbezogenen Daten gespeichert ? </h3>
          <p>
            Entsprechend der Verpflichtung aus der Corona - Schutz - Verordnung
            M - V bewahren wir Ihre Angaben tagesaktuell für vier Wochen auf und
            vernichten die Angaben unmittelbar nach Ablauf dieser Frist.
          </p>
          <h3>Was passiert, wenn keine Angaben gemacht werden?</h3>
          <p>
            Verweigern Sie die Angaben, dürfen wir Sie leider nicht bedient
            werden.
          </p>
          <h3>Welche Rechte haben Sie?</h3>
          <p>
            Sie haben das Recht auf Auskunft gem. Art. 15 DS-GVO, auf
            Berichtigung falscher personenbezogener Daten gem. Art. 16 DS-GVO,
            auf Löschung gem. Art. 17 DS-GVO und auf eine Einschränkung der
            Verarbeitung gem. Art. 18 DS-GVO. Machen Sie entsprechende Ansprüche
            geltend, prüfen wir, ob die gesetzlichen Voraussetzungen vorliegen
            und wir Ihrem Anspruch nachkommen können.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Datenschutz;
