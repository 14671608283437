import React, { useState } from "react";
import QRCode from "qrcode.react";
import vCardsJS from "vcards-js";
import { useForm } from "react-hook-form";
import "./createVCard.css";

const CreateVCard = () => {
  const [submitting, setSubmitting] = useState(false);
  const [createQR, setCreateQR] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLasttName] = useState("");
  const [streetName, setSteetName] = useState("");
  const [streetNumber, setSteetNumber] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    console.log(data);

    setSubmitting(true);
    setFirstName(data.firstname);
    setLasttName(data.lastname);
    setSteetName(data.streetname);
    setSteetNumber(data.streetnumber);
    setZip(data.zip);
    setCity(data.city);
    setEmail(data.email);
    setPhone(data.phone);

    setTimeout(() => {
      setCreateQR(true);
      setSubmitting(false);
    }, 50);
  };

  const getVsCard = () => {
    var vCard = vCardsJS();
    vCard.version = "4.0";
    vCard.firstName = firstName;
    vCard.lastName = lastName;
    vCard.cellPhone = phone;
    vCard.email = email;
    vCard.homeAddress.street = streetName + " " + streetNumber;
    vCard.homeAddress.postalCode = zip;
    vCard.homeAddress.city = city;

    return vCard.getFormattedString();
  };

  const saveCanvas = () => {
    setTimeout(() => {
      const canvasSave = document.getElementById("quickQR");
      const downloadLink = document.getElementById("downloadLink");
      const d = canvasSave
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      const link = document.createElement("a");
      link.href = d;
      link.download = "qrcode.jpg";
      link.text = "Download QR Code";
      downloadLink.innerHTML = "";
      downloadLink.append(link);
    }, 500);
  };

  return (
    <section title="QR Code" id="quickstart">
      <div className="wrapper">
        <div>
          <h1>QR Code Erstellung ohne Registrierung</h1>
          {submitting && <div>Erstelle QR Code</div>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={submitting}>
              <label>
                <p>Vorname</p>
                <input
                  name="firstname"
                  ref={register({ required: true, minLength: 2 })}
                />
                {errors.firstname && (
                  <p className="red">Dies ist ein Pflichtfeld</p>
                )}
              </label>
              <label className="noMargin">
                <p>Nachname</p>
                <input name="lastname" ref={register({ required: true })} />
                {errors.lastname && (
                  <p className="red">Dies ist ein Pflichtfeld</p>
                )}
              </label>
            </fieldset>
            <fieldset disabled={submitting}>
              <label>
                <p>Strasse</p>
                <input name="streetname" ref={register({ required: true })} />
                {errors.streetname && (
                  <p className="red">Dies ist ein Pflichtfeld</p>
                )}
              </label>
              <label className="XSInput noMargin">
                <p>Nr.</p>
                <input name="streetnumber" ref={register({ required: true })} />
                {errors.streetnumber && (
                  <p className="red">Dies ist ein Pflichtfeld</p>
                )}
              </label>
            </fieldset>
            <fieldset disabled={submitting}>
              <label className="shortInput">
                <p>PLZ</p>
                <input name="zip" ref={register({ required: true })} />
                {errors.zip && (
                  <p className="red">Dies ist ein Pflichtfeld</p>
                )}
              </label>
              <label className="noMargin">
                <p>Ort</p>
                <input name="city" ref={register({ required: true })} />
                {errors.city && (
                  <p className="red">Dies ist ein Pflichtfeld</p>
                )}
              </label>
            </fieldset>
            <fieldset disabled={submitting}>
              <label>
                <p>Telefon</p>
                <input name="phone" ref={register({ required: true })} />
                {errors.phone && (
                  <p className="red">Dies ist ein Pflichtfeld</p>
                )}
              </label>
              <label className="noMargin">
                <p>E-Mail</p>
                <input
                  name="email"
                  ref={register({
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Das ist leider keine E-Mail Adresse",
                    },
                  })}
                />
                {errors.email && (<p className="red">{errors.email.message}</p>)}
              </label>
            </fieldset>
            <button className="btn submit" type="submit" disabled={submitting}>
              ERSTELLE QR CODE
            </button>
          </form>
          <br />
          <br />
          {createQR ? (
            <div>
              <QRCode value={getVsCard()} level="Q" size={250} id="quickQR" />
              <div id="downloadLink"></div>
              {saveCanvas()}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
};

export default CreateVCard;
