import React from 'react';
import '../../App.css';
import Cards from '../Cards/Cards';
import HeroSection from '../HeroSection';
import VCard from '../createVCard/createVCard';
import Footer from '../Footer';

function Home() {
    return (
        <>
            <HeroSection />
            <Cards />
            <VCard /> 
            <Footer />
        </>
    )
}

export default Home;


