import React from 'react'
import CardItem from './CardItem/CardItem'

import './Cards.css'

function Cards() {
    return (
        <div className='cards'>
            <h1>Wie funktioniert das?</h1>
            <div className="cards__container">
                <div className="cards__wrapper">
                    <ul className="cards__items">
                        <CardItem
                            src="images/on_smartphone.jpg"
                            alt="QR Code1"
                            text="Bist du in einem Restaurant, Café, Bar oder einer anderen Location wo man der Registrierungspflicht unterliegt?<br/><br/>Dann gehe auf die StaySafe-Online Website und erstelle ganz einfach deinen persönlichen QR Code."
                            label="QR CODE"
                            path='#'
                        />
                        <CardItem
                            src="images/JungeFrau.jpg"
                            alt="QR Code"
                            text="Nun zeige den QR Code jemandem vom Personal.<br><br>Der QR Code wird dann eingescannt und das war es auch schon."
                            label="QR CODE"

                            path='#'
                        />

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Cards
