import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <section title="QR Code" id="heroSection">
      <div className="hero-container">
        <h1>Stay Safe Online</h1>
        <p>Nie mehr Zettel ausfüllen!</p>
        <div className="hero-btns">
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
            location="quickstart"
          >
            Schnellstart
          </Button>
     
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
