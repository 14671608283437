import React, { Component } from "react";
import QrReader from "react-qr-scanner";

import Footer from "../Footer";

export default class QRReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 0,
      result: "Halte die Kamera über den QR Code",
      dataScanned: false,
    };
    this.calcHeight = (360 / 360) * ((window.innerWidth / 100) * 90);
    this.handleScan = this.handleScan.bind(this);
    this.scanData = {}
  }

  vCardParse(input) {
    var Re1 = /^(version|fn|title|org):(.+)$/i;
    var Re2 = /^([^:;]+);([^:]+):(.+)$/;
    var ReKey = /item\d{1,2}\./;
    var fields = {};

    input.split(/\r\n|\r|\n/).forEach(function (line) {
      var results, key;

      if (Re1.test(line)) {
        results = line.match(Re1);
        key = results[1].toLowerCase();
        fields[key] = results[2];
      } else if (Re2.test(line)) {
        results = line.match(Re2);
        key = results[1].replace(ReKey, "").toLowerCase();

        var meta = {};
        results[2]
          .split(";")
          .map(function (p, i) {
            var match = p.match(/([a-z]+)=(.*)/i);
            if (match) {
              return [match[1], match[2]];
            } else {
              return ["TYPE" + (i === 0 ? "" : i), p];
            }
          })
          .forEach(function (p) {
            meta[p[0]] = p[1];
          });

        if (!fields[key]) fields[key] = [];

        fields[key].push({
          meta: meta,
          value: results[3].split(";"),
        });
      }
    });

    return fields;
  }

  handleScan(data) {

    if (data != null && !this.state.dataScanned) {
      this.setState({
        result: JSON.stringify(this.vCardParse(data)),
        dataScanned: true,
      });
    }
  }
  handleError(err) {
    console.error(err);
  }

  render() {
    const previewStyle = {
      width: (window.innerWidth / 100) * 90,
      height: this.calcHeight,
      objectFit: "fill",
    };

    const camStyle = {
      display: "flex",
      justfiyContent: "center",
    };
    const textStyle = {
      fontSize: "30px",
      "text-align": "center",
    };

    return (
      <>
        <section id="QRReader">
          <div>
            <h1>QR Reader</h1>
            {!this.state.dataScanned ? (
              <QrReader
                delay={this.state.delay}
                style={previewStyle}
                onError={this.handleError}
                onScan={this.handleScan}
                facingMode="rear"
              />
            ) : (
              <p style={textStyle}>{this.state.result}</p>
            )}
          </div>
          <div>{this.calculation}</div>
        </section>
        <Footer />
      </>
    );
  }Í
}
